import { buildUrl, formatParam } from "../Utility";


export const addBusinessNodeAPI = (username, password, businessId, noteStr) => {
    return fetch(buildUrl("scraping/business/" + formatParam(businessId) + "/notes?username=" + formatParam(username) + "&password=" + formatParam(password) + "&noteStr=" + formatParam(noteStr)) , { method: "PUT"})
}

export const setBusinessTaskStatusAPI = (username, password, taskId, status) => {
    return fetch(buildUrl("scraping/tasks/" + formatParam(taskId) + "/status?username=" + formatParam(username) + "&password=" + formatParam(password) + "&status=" + formatParam(status)) , { method: "PUT"})
}

export const fetchBusinessAPI = (username, password, businessId) => {
    return fetch(buildUrl("scraping/business/" + formatParam(businessId) + "?username=" + formatParam(username) + "&password=" + formatParam(password)) , { method: "GET"})
}

export const fetchAudiencesAPI = (username, password) => {
    return fetch(buildUrl("scraping/audiences?username=" + formatParam(username) + "&password=" + formatParam(password)) , { method: "GET"})
}

export const exportAudienceAPI = (username, password, audienceId) => {
    return fetch(buildUrl("scraping/audiences/" + formatParam(audienceId) + "/export?username=" + formatParam(username) + "&password=" + formatParam(password)) , { method: "GET"})
}

export const importAudienceAPI = (username, password, audienceJson, owner) => {
    return fetch(buildUrl("scraping/audiences/import?username=" + formatParam(username) + "&password=" + formatParam(password) + "&owner=" + formatParam(owner))  , { method: "POST", body: audienceJson})
}

/* Tasks */

export const fetchActiveTasksAPI = (username, password) => {
    return fetch(buildUrl("scraping/active_tasks?username=" + formatParam(username) + "&password=" + formatParam(password)) , { method: "GET"})
}

export const fetchUserTasksAPI = (username, password) => {
    return fetch(buildUrl("scraping/user/tasks?username=" + formatParam(username) + "&password=" + formatParam(password)) , { method: "GET"})
}

export const createRangeAudienceAPI = (username, password, name, branchId, minZipCode, maxZipCode, minAge, maxAge) => {
    return fetch(buildUrl("scraping/range_audience?username=" + formatParam(username) + "&password=" + formatParam(password) + "&name=" + formatParam(name) + "&branchId=" + formatParam(branchId) + "&minZipCode=" + formatParam(minZipCode) + "&maxZipCode=" + formatParam(maxZipCode) + "&minAge=" + formatParam(minAge) + "&maxAge=" + formatParam(maxAge)) , { method: "PUT"})
}






export const createRadiusAudienceAPI = (username, password, name, sourceName, zipCode, radius, argumentMap) => {
    return fetch(buildUrl("scraping/radial_audience?username=" + formatParam(username) + "&password=" + formatParam(password) + "&name=" + formatParam(name) + "&sourceName=" + formatParam(sourceName) + "&zipCode=" + formatParam(zipCode) + "&radius=" + formatParam(radius)) , 
        { 
            method: "POST", 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(argumentMap), 
        }
    )
}





export const setShownAPI = (username, password, phoneNo) => {
    return fetch(buildUrl("scraping/phoneNo/" + formatParam(phoneNo) + "/shown?username=" + formatParam(username) + "&password=" + formatParam(password)) , { method: "PUT"})
}

export const setReachedAPI = (username, password, phoneNo) => {
    return fetch(buildUrl("scraping/phoneNo/" + formatParam(phoneNo) + "/reached?username=" + formatParam(username) + "&password=" + formatParam(password)) , { method: "PUT"})
} 

//    @PutMapping(value = "/business/{id}/tasks") 


export const createBusinessTaksPI = (username, password, businessId, day, month, hour, min, description) => {
    return fetch(buildUrl("scraping/business/" + formatParam(businessId) + "/tasks?username=" + formatParam(username) + "&password=" + formatParam(password) + "&day=" + formatParam(day) + "&month=" + formatParam(month) + "&hour=" + formatParam(hour) + "&min=" + formatParam(min) + "&description=" + formatParam(description)), { method: "PUT"})
}
 

export const fetchBranchesAPI = (username, password) => {
    return fetch(buildUrl("scraping/branches?username=" + formatParam(username) + "&password=" + formatParam(password)) , { method: "GET"})
}
 

/* Sources */

export const fetchSourcesAPI = (username, password) => {
    return fetch(buildUrl("scraping/sources?username=" + formatParam(username) + "&password=" + formatParam(password)) , { method: "GET"})
}
 
export const fetchInterfaceAPI = (sourceName, username, password) => {
    return fetch(buildUrl("scraping/source/" + sourceName +  "/descriptor?username=" + formatParam(username) + "&password=" + formatParam(password)) , { method: "GET"})
}