import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
} from "react-router-dom";


import Snackbar from '@mui/material/Snackbar';

import { Button, Checkbox, Divider, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { acceptOfferAPI, authenticate, disableAPI, saveEmailAddressAPI, saveImprintAPI, saveIsAdminAPI, saveIsCallAgentAPI, saveIsPurchaserAPI, saveIsSubscriberAPI, saveLastnameNameAPI, savePasswordAPI, savePrenameNameAPI, saveUsername, saveUsernameAPI } from './api/user_api';
import { MSG_CHANGES_SAVED, TabPanel, a11yProps, handleResponse } from './Utility';
import Profile from './sections/Profile';
import Users from './sections/Users';
import Purchases from './sections/Purchases';
import User from './fragments/User';
import Sales from './sections/Sales';
import Offers from './sections/Offers';
import Auctions from './sections/Auctions';
import Confirmation from './sections/Confirmation';
import AuctionWrapper from './router/AuctionWrapper';
import OfferWrapper from './router/OfferWrapper';
import Imprint from './legal/Imprint';
import UserImprint from './legal/UserImprint';
import LeadWrapper from './router/LeadWrapper';
import Privacy from './legal/Privacy';
import GitHubIcon from '@material-ui/icons/GitHub';
import { PictureAsPdf } from '@material-ui/icons';
import LegalOffer from './LegalOffer';
import Invoices from './sections/Invoices';
import { InvoicingWrapper } from './fragments/Invoice';
import Audience from './sections/Audience';

export const PRIMARY_COLOR = "rgb(12, 5, 109)"
export const SECONDARY_COLOR = "rgb(83, 64, 255)";

export const HTTP_STATUS_SUCCESS = 200;

/*

const PROTOCOL = "http"
const DOAMAIN = "localhost";
const PORT = "8080"*/


const PROTOCOL = "https" 
const DOAMAIN = "marketplace.p-space.io";
const PORT = "8443"


const APP_BASE_URL = ""

export const HM_LOGO_URL = "https://www.hansemerkur.de/resource/blob/25888/15fc189a43f3efe4588f88cc84fa5545/hm-logo-data.svg"


const LOGO_URL = "https://marketplace.p-space.io/pspace.png"


const LABEL_PROFILE = "Profil";
const LABEL_USERS = "Benutzer"
const LABEL_PURCHASES = "Meine Käufe"

const LABEL_SALES = "Meine Verkäufe"
const LABEL_MARKETPLACE = "Marktplatz"
const LABEL_OFFER = "Sofortkauf"
const LABEL_AUCTION = "Auktion" 
const LABEL_INVOICING = "Rechnungen"
const LABEL_USERNAME = "Benutzername"
const LABEL_PASSWORD = "Passwort"
const LABEL_LOG_IN = "Log In"
const LABEL_SPOTLIGHT = "Spotlight"
const LABEL_AUDIENCE = "Audience"


const MSG_LOGIN_SUCCESSFUL = "Willkommen!";
const MSG_LOGIN_FAILED = "Zugriff verweigert!";


const MSG_ERROR_OCCURED = "Es trat ein Fehler auf. HTTP-Status-Code:";


export function buildUrl(urlFragment) {
    return PROTOCOL + "://" + DOAMAIN + ":" + PORT + "/" + APP_BASE_URL + urlFragment;
}

export function formatParam(param) {
    if (param == null) {
        return "";
    }
    else {
        return param;
    }
}

function App() {

    const [context, setContext] = useState(null);
    const [updateContext, setUpdateContext] = useState(false);


    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {

        const doUpdateContext = async () => {
            setContext(await authenticate(username, password, showMessage));
            context.password = password
        }

        if (updateContext) {
            if (context !== null) {
                doUpdateContext()
            }
            setUpdateContext(false);
        }
    })



    /*App API*/

    const showMessage = (msg) => {
        setSnackbarOpen(true);
        setSnackbarMsg(msg);
    }

    /*Handler*/

    const login = async () => {
        const context = await authenticate(username, password, showMessage)
        if (context == null) {
            showMessage(MSG_LOGIN_FAILED);
        }
        else {
            setContext(context);
            showMessage(MSG_LOGIN_SUCCESSFUL);
        }
    }


    const contextAPI = {
        id: null,
        username: null,
        password: null,
        firstName: null,
        lastName: null,
        emailAddress: null,
        subscriberBudget: null,
        disabled: null,
        callAgent: null,
        subscriber: null,
        purchaser: null,
        admin: null,
        contract: null,
        update: () => setUpdateContext(true),
        logout: () => setContext(null)
    }

    if (context != null) {
        contextAPI.id = context.id;
        contextAPI.username = context.username;
        contextAPI.password = password;
        contextAPI.firstName = context.firstName;
        contextAPI.lastName = context.lastName;
        contextAPI.emailAddress = context.emailAddress;
        contextAPI.subscriberBudget = context.subscriberBudget
        contextAPI.disabled = context.disabled
        contextAPI.callAgent = context.callAgent
        contextAPI.subscriber = context.subscriber
        contextAPI.purchaser = context.purchaser
        contextAPI.admin = context.admin
        contextAPI.contract = context.contract
        contextAPI.update = () => setUpdateContext(true);
        contextAPI.logout = () => setContext(null);
    }

    const appAPI = {
        showMessage: showMessage,
    }


    return (
        <Router>
            <Box>
                <Paper style={{ display: "block", witdh: "80%", marginLeft: "auto", marginRight: "auto", maxWidth: "1200px", marginTop: "50px", marginBottom: "50px" }}>
                    <Routes>
                        <Route path="/" element={
                            <Container
                                context={context}
                                contextAPI={contextAPI}
                                appAPI={appAPI}
                                content={
                                    <Profile
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                        userId={contextAPI.id}
                                    />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={true}
                                showTabs={true}
                                tabIndex={0}
                            />
                        } />
                        <Route path="/users" element={
                            <Container
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                content={
                                    <Users
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                    />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={true}
                                showTabs={true}
                                tabIndex={1}
                            />
                        } />
                        <Route path="/purchases" element={
                            <Container
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                content={
                                    <Purchases
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                    />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={true}
                                showTabs={true}
                                tabIndex={2}
                            />
                        } />
                        <Route path="/sales" element={
                            <Container
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                content={
                                    <Sales
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                    />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={true}
                                showTabs={true}
                                tabIndex={3}
                            />
                        } />
                        <Route path="/offers" element={
                            <Container
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                content={
                                    <Offers
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                    />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={true}
                                showTabs={true}
                                tabIndex={4}
                            />
                        } />
                        <Route path="/auctions" element={
                            <Container
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                content={
                                    <Auctions
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                    />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={true}
                                showTabs={true}
                                tabIndex={5}
                            />
                        } />
                         <Route path="/invoicing" element={
                            <Container
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                content={
                                    <Invoices
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                    />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={true}
                                showTabs={true}
                                tabIndex={6}
                            />
                        } />
                        <Route path="/audience" element={
                            <Container
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                content={
                                    <Audience
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                    />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={true}
                                showTabs={true}
                                tabIndex={7} 
                            />
                        } />
                        <Route path="/invoicing/:leadId" element={
                            <Container
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                content={
                                    <InvoicingWrapper
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                    />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={true}
                                showTabs={true}
                                tabIndex={8}
                            />
                        } />
                        <Route path="/leads/:leadId" element={
                            <Container
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                content={
                                    <LeadWrapper
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                    />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={true}
                                showTabs={true}
                                tabIndex={8}
                            />
                        } />
                        <Route path="/auctions/:auctionId" element={
                            <Container
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                content={
                                    <AuctionWrapper
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                    />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={true}
                                showTabs={true}
                                tabIndex={8}
                            />
                        } />
                        <Route path="/offers/:offerId" element={
                            <Container
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                content={
                                    <OfferWrapper
                                        appAPI={appAPI}
                                        contextAPI={contextAPI}
                                    />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={true}
                                showTabs={true}
                                tabIndex={8}
                            />
                        } />

                        <Route path="/confirmation/:leadId/:confirmationCode" element={
                            <Confirmation
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                            />
                        } />
                        <Route path="/imprint" element={
                            <Container
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                content={
                                    <Box style={{ display: "block", width: "90%", marginLeft: "auto", marginRight: "auto", paddingTop: "25px", paddingBottom: "50px" }}>
                                        <Imprint />
                                    </Box>
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={false}
                                showTabs={false}
                            />
                        } />
                        <Route path="/privacy" element={
                            <Container
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                content={
                                    <Privacy />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={false}
                                showTabs={false}
                            />
                        } />
                        <Route path="/imprint/:userId" element={
                            <Container
                                context={context}
                                contextAPI={contextAPI}
                                content={
                                    <UserImprint
                                        appAPI={appAPI}
                                    />
                                }
                                username={username}
                                password={password}
                                setUsername={setUsername}
                                setPassword={setPassword}
                                login={login}
                                authRequired={false}
                                showTabs={false}
                            />
                        } />
                    </Routes>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={2000}
                        onClose={() => setSnackbarOpen(false)}
                        message={snackbarMsg}
                    />
                </Paper>
                <Box style={{ display: "block", width: "252px", marginLeft: "auto", marginRight: "auto" }}>
                    <a target="_blank" style={{ display: "inline-block", marginBottom: "10px", textDecoration: "none", fontFamily: "Arial, Helvetica", color: PRIMARY_COLOR }} href="/imprint">
                        Impressum
                    </a>
                    <a target="_blank" style={{ display: "inline-block", marginLeft: "25px", marginBottom: "50px", textDecoration: "none", fontFamily: "Arial, Helvetica", color: PRIMARY_COLOR }} href="./legal/AGB_v1.pdf">
                        AGB
                    </a>
                    <a target="_blank" style={{ display: "inline-block", marginLeft: "25px", marginBottom: "50px", textDecoration: "none", fontFamily: "Arial, Helvetica", color: PRIMARY_COLOR }} href="./legal/Privacy_1.pdf">
                        Datenschutz
                    </a>
                </Box>
            </Box>
        </Router>
    );
}

function Container(props) {

    const authRequired = props.authRequired;
    const context = props.context;
    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;
    const content = props.content;
    const username = props.username
    const password = props.password
    const setUsername = props.setUsername;
    const setPassword = props.setPassword;
    const login = props.login;
    const showTabs = props.showTabs
    const tabIndex = props.tabIndex

    console.log(useLocation());




    return (
        <Box>
            <Box style={{ backgroundColor: PRIMARY_COLOR }}>
                <img src={LOGO_URL} alt="Logo" style={{ width: "150px", display: "block", padding: "25px", marginLeft: "auto", marginRight: "auto" }} />
            </Box>
            <Box style={{ display: "block", width: "90%", marginLeft: "auto", marginRight: "auto", paddingTop: "25px", paddingBottom: "50px" }}>
                {
                    context != null || !authRequired ?
                        (context != null && (context.contract.offeredFee != null || context.contract.offeredTerms != null)) ?
                            <LegalOffer
                                context={context}
                                appAPI={appAPI}
                                contextAPI={contextAPI}
                                password={password}
                            />
                            :
                            (
                                showTabs ?
                                    <Box>
                                        <Tabs value={tabIndex} style={{ marginBottom: "25px" }} TabIndicatorProps={{ style: { background: PRIMARY_COLOR } }}>
                                            <Tab label={LABEL_PROFILE} {...a11yProps(0)} component={Link} to={"/"} />
                                            <Tab label={LABEL_USERS} {...a11yProps(1)} disabled={!context.admin} component={Link} to={"/users"} />

                                            <Tab label={LABEL_PURCHASES} {...a11yProps(2)} disabled={!(context.admin || context.subscriber || context.purchaser)} component={Link} to={"/purchases"} />
                                            <Tab label={LABEL_SALES} {...a11yProps(3)} disabled={!(context.admin || context.callAgent)} component={Link} to={"/sales"} />
                                            <Tab label={LABEL_OFFER} {...a11yProps(4)} disabled={!(context.admin || context.purchaser)} component={Link} to={"/offers"} />
                                            <Tab label={LABEL_AUCTION} {...a11yProps(5)} disabled={!(context.admin || context.purchaser)} component={Link} to={"/auctions"} />
                                            <Tab label={LABEL_INVOICING} {...a11yProps(6)} disabled={!(context.admin || context.purchaser || context.callAgent)} component={Link} to={"/invoicing"} />
                                            <Tab label={LABEL_AUDIENCE} {...a11yProps(7)} disabled={!(context.admin || context.callAgent)} component={Link} to={"/audience"}  />
                                            <Tab label={LABEL_SPOTLIGHT} {...a11yProps(8)} disabled={true} />
                                        </Tabs>
                                        <Box style={{ fontFamily: "Arial, Helvetica" }}>
                                            {content}
                                        </Box>
                                    </Box> 
                                    :
                                    <Box style={{ fontFamily: "Arial, Helvetica" }}> 
                                        {content}
                                    </Box>
                            )
                        :
                        <Authentication
                            username={username}
                            password={password}
                            setUsername={setUsername}
                            setPassword={setPassword}
                            login={login}
                        />
                }
            </Box>
        </Box>

    )

}



function Authentication(props) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <TextField
                    value={props.username == null ? "" : props.username}
                    onChange={(e) => props.setUsername(e.target.value)}
                    style={{ color: SECONDARY_COLOR }}
                    label={LABEL_USERNAME}
                    variant="standard"
                    fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    value={props.password == null ? "" : props.password}
                    onChange={(e) => props.setPassword(e.target.value)}
                    style={{ color: SECONDARY_COLOR }}
                    label={LABEL_PASSWORD}
                    variant="standard"
                    type="password"
                    fullWidth />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    style={{ backgroundColor: SECONDARY_COLOR }}
                    onClick={props.login}
                >
                    {LABEL_LOG_IN}
                </Button>
            </Grid>
        </Grid>
    )
}



export default App;

