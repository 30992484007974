import { Accordion, AccordionSummary, AccordionDetails, Box, Grid, Paper, Typography, TextField, Button, Divider, Select, MenuItem, FormControl, InputLabel, IconButton, useMediaQuery, FormGroup, FormControlLabel, Switch, ListItem, AppBar, Toolbar, InputAdornment } from "@mui/material";

import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IosShareIcon from '@mui/icons-material/IosShare';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import UploadIcon from '@mui/icons-material/Upload';
import CloseIcon from '@mui/icons-material/Close';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LanguageIcon from '@mui/icons-material/Language';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListIcon from '@mui/icons-material/List';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NotesIcon from '@mui/icons-material/Notes';
import SyncIcon from '@mui/icons-material/Sync';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import InfoIcon from '@mui/icons-material/Info';
import NoteIcon from '@mui/icons-material/Note';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import WarningIcon from '@mui/icons-material/Warning';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';

import LoopIcon from '@mui/icons-material/Loop';
import DoneIcon from '@mui/icons-material/Done';
import DownloadIcon from '@mui/icons-material/Download';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DeleteIcon from "@mui/icons-material/Delete";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

import { PRIMARY_COLOR, SECONDARY_COLOR } from "../App";
import { addBusinessNodeAPI, createBusinessTaksPI, createRadiusAudienceAPI, createRangeAudienceAPI, exportAudienceAPI, fetchActiveTasksAPI, fetchAudiencesAPI, fetchBranchesAPI, fetchBusinessAPI, fetchInterfaceAPI, fetchSources, fetchSourcesAPI, fetchUserTasksAPI, importAudienceAPI, setBusinessTaskStatusAPI, setReachedAPI, setShownAPI } from "../api/audiences_api";
import { dayCount, handleResponse, LABEL_CLOSE, LABEL_PHONE_NUMBER } from "../Utility";
import { addNoteAPI } from "../api/lead_api";
import { useTheme } from "@emotion/react";
import AddLead from "../fragments/AddLead";

const LABEL_AUDIENCE_LIST = "Audiences"
const LABEL_ACTIONS = "Aktionen"
const LABEL_ADD = "Neue Audience"
const LABEL_EXPORT = "Audience exportieren"

const LABEL_COMPLETED = "Abgeschlossen."

const LABEL_SHARE = "Teilen"
const LABEL_ADD_TASK = "Neue Aufgabe"
const LABEL_NEXT = "Weiter";
const LABEL_PREV = "Zurück";
const LABLE_NAV = "Navigation"
const LABEL_WEB = "Web"
const LABEL_PHONE = "Telefon"
const LABEL_EMAIL = "E-Mail"
const LABEL_ADDRESS = "Adresse"
const LABEL_NOTES = "Notizen"
const LABEL_CREATE = "Anlegen"
const LABEL_TASKS = "Aufgaben"
const LABEL_SELECTIONS = "Selektionen"
const LABEL_DESCRIPTION = "Beschreibung"
const LABEL_DUE_BY = "Fälligkeit"
const LABEL_IN_CHARAGE = "Zuständigkeit"
const LABEL_STATUS = "Status"
const LABEL_STATUS_OPEN = "Offen"
const LABEL_STATUS_DONE = "Erledigt"
const LABEL_STATUS_CLOSE = "Schließen"
const LABEL_NEW_TASK = "Neue Aufgabe"
const LABEL_NEW_LEAD = "Neuer Lead"
const LABEL_CANCEL = "Abbrechen";
const LABEL_SAVE = "Speichern"
const LABEL_DAY = "Tag"
const LABEL_TIME = "Uhrzeit"


const roleDict = {
    OWNER: "Einzelunternehmer",
    DIRECTOR: "Geschäftsführer",
    SHAREHOLDER: "Gesellschafter",
    LIMITED_PARTNER: "Kommanditist",
    GENERAL_PARTNER: "Komplementär",

}

const trim = (str, max) => {
    if (str == null) {
        return null;
    }
    if (str.length <= max) {
        return str.substring(0, str.length - 3) + "..."
    }
    else {
        return str.substring(0, max - 1) + "..."
    }
}

export default function Audience(props) {


    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI

    const [audience, setAudience] = React.useState(null);
    const [businessIndex, setBusinessIndex] = React.useState(null);
    const [business, setBusiness] = React.useState(null);


    const [skip, setSkip] = React.useState(false);
    const [next, setNext] = React.useState(false);
    const [direction, setDirection] = React.useState(1);


    const [audicenceList, setAudienceList] = React.useState([]);
    const [activeTaskList, setActiveTaskList] = React.useState([])
    const [userTaskList, setUserTaskList] = React.useState([])
    const [branchList, setBranchList] = React.useState([{}, {}])

    const [paramPrefixDict, setValuePrefixDict] = React.useState({});
    const [paramValuesDict, setParamValuesDict] = React.useState({});

    const [sourceList, setSourceList] = React.useState([])

    const [source, setSource] = React.useState(-1)
    const [descriptorList, setDescriptorList] = React.useState([])

    const [updateAudienceList, setUpdateAudienceList] = React.useState(true)
    const [updateActiveTaskList, setUpdateActiveTaskList] = React.useState(true)
    const [updateUserTaskList, setUpdateUserTaskList] = React.useState(true)
    const [updateBranchList, setUpdateBranchList] = React.useState(true)
    const [updateSourceList, setUpdateSourceList] = React.useState(true)
    const [updateDescriptorList, setUpdateDescriptorList] = React.useState(true)


    const [showCreateAudienceDialog, setShowCreateAudienceDialog] = React.useState(false)
    const [showCreateTaskDialog, setShowCreateTaskDialog] = React.useState(false)
    const [showAddLead, setShowAddLead] = React.useState(false);
    const [showImportAudienceDialog, setShowImportAudienceDialog] = React.useState(false);

    const [showWebsite, setShowWebsite] = React.useState(false)
    const [websiteUrl, setWebsiteUrl] = React.useState("https://marketplace.p-space.io/audience")

    /*Source API*/

    const clearParamValuePrefixDicts = () => {
        setValuePrefixDict({})
        setParamValuesDict({})
    }

    const updateSource = (source) => {
        //if(sourceList.includes(source)) {
        setSource(source);
        setUpdateDescriptorList(true);
        clearParamValuePrefixDicts();
        //}
    }

    const setParamter = (name, value) => {
        const dictCpy = JSON.parse(JSON.stringify(paramValuesDict))
        if(value == null) {
            dictCpy[name] = [];
        } 
        else {
            dictCpy[name] = [value];
        }
        setParamValuesDict(dictCpy);
    }
 
    const getParameter = (name) => {
        if (name in paramValuesDict) {
            return paramValuesDict[name]
        }
        else return null;
    }

    const setParamterPrefix = (name, prefix) => {
        const dictCpy = JSON.parse(JSON.stringify(paramPrefixDict))
        dictCpy[name] = prefix;
        setValuePrefixDict(dictCpy);
    }

    const getParameterPrefix = (name) => {
        if (name in paramPrefixDict) {
            return paramPrefixDict[name]
        }
        else return null;
    }


    const validateInput = () => {
        if (!updateDescriptorList) {
            for (let index = 0; index < descriptorList.length; index++) {

                const descriptor = descriptorList[index];

                if (descriptor.name == "zip_code") {
                    continue;
                }

                let values;

                for (let key in paramValuesDict) {
                    if (descriptor.name == key) {
                        values = paramValuesDict[key];
                    }
                }



                if (values == null || values.length == 0) {
                    if (!descriptor.optional) {
                        return false;
                    }
                }



                else if (values.length > 1 && !descriptor.multiValueParameter) {
                    return false;
                }



                if (values != null) {
                    for (let valueIndex = 0; valueIndex < values.length; valueIndex++) {
                        if (descriptor.defaultValues.length > 0) {
                            if (!descriptor.defaultValues.includes(values[valueIndex])) {
                                return false;
                            }
                        }
                        else if (descriptor.javascriptRegex != null && !new RegExp(descriptor.javascriptRegex).test(values[valueIndex])) {
                            return false;
                        }
                    }
                }

            }
            return true;
        }
        return false;
    }  

    /*Business API*/


    const openAudience = (audience) => {
        setAudience(audience)
        if (audience.businessIdList.length > 0) {
            const BUSINESS_INDEX = 0;
            setBusinessIndex(BUSINESS_INDEX);
            openBusiness(audience.businessIdList[BUSINESS_INDEX]);
        }
    }

    const openAudienceBusinessById = (audienceId, businessId) => {
        const audienceMatchList = audicenceList.filter(audience => audience.audienceId == audienceId);
        if (audicenceList.length > 0) {
            const audience = audienceMatchList[0];
            for (let index = 0; index < audience.businessIdList.length; index++) {

                if (businessId == audience.businessIdList[index]) {
                    setAudience(audience);
                    setBusinessIndex(index);
                    openBusiness(businessId)
                    break;
                }
            }
        }
    }

    const openBusiness = (businessId, businessIndex) => {
        handleResponse(fetchBusinessAPI(contextAPI.username, contextAPI.password, businessId), (response) => {
            setBusiness(response);
            if (skip && response.reachedTimestamp != null && businessIndex != null && businessIndex < audience.businessIdList.length - 2) {
                setNext(true);
            }
        }, appAPI.showMessage);
    }

    const updateBusiness = () => {
        if (business != null) {
            handleResponse(fetchBusinessAPI(contextAPI.username, contextAPI.password, business.businessId), (response) => {
                setBusiness(response);
            }, appAPI.showMessage);
        }
    }

    const openNextBusiness = () => {
        if (audience != null && businessIndex != null && businessIndex < audience.businessIdList.length) {
            const BUSINESS_INDEX = businessIndex + 1;
            setBusinessIndex(BUSINESS_INDEX)
            openBusiness(audience.businessIdList[BUSINESS_INDEX], BUSINESS_INDEX);
        }
    }

    const openPrevBusiness = () => {
        if (audience != null && businessIndex != null && audience.businessIdList.length > 0 && businessIndex > 0) {
            const BUSINESS_INDEX = businessIndex - 1;
            setBusinessIndex(BUSINESS_INDEX)
            openBusiness(audience.businessIdList[BUSINESS_INDEX], BUSINESS_INDEX);
        }
    }

    const exportAudience = () => {
        if (audience != null) {
            handleResponse(exportAudienceAPI(contextAPI.username, contextAPI.password, audience.audienceId), (response) => { navigator.clipboard.writeText(JSON.stringify(response)); appAPI.showMessage(LABEL_COMPLETED) }, appAPI.showMessage)
        }
    }

    const importAudience = (json, owner) => {
        handleResponse(importAudienceAPI(contextAPI.username, contextAPI.password, json, owner), (response) => { appAPI.showMessage(LABEL_COMPLETED) }, appAPI.showMessage)
    }

    /*Task API*/

    const createTask = (businesId, day, month, hour, min, description) => {
        handleResponse(createBusinessTaksPI(contextAPI.username, contextAPI.password, businesId, day, month, hour, min, description), (response) => openBusiness(businesId), appAPI.showMessage);
    }


    const setTaskStatus = (taskID, status) => {
        handleResponse(setBusinessTaskStatusAPI(contextAPI.username, contextAPI.password, taskID, status), (response) => openBusiness(audience.businessIdList[businessIndex]), appAPI.showMessage);
    }


    /*Note API*/

    const createNote = (noteStr) => {
        if (audience != null && businessIndex != null && 0 <= businessIndex && businessIndex < audience.businessIdList.length) {
            handleResponse(addBusinessNodeAPI(contextAPI.username, contextAPI.password, audience.businessIdList[businessIndex], noteStr), (response) => openBusiness(audience.businessIdList[businessIndex]), appAPI.showMessage);
        }
    }


    const openWebsite = (url) => {
        setWebsiteUrl(url);
        setShowWebsite(true)
    }


    const doUpdateActiveTaskList = () => {
        handleResponse(fetchActiveTasksAPI(contextAPI.username, contextAPI.password), (response) => setActiveTaskList(response), appAPI.showMessage);
        setUpdateActiveTaskList(false)
    }

    const doUpdateAudienceList = () => {
        handleResponse(fetchAudiencesAPI(contextAPI.username, contextAPI.password), (response) => setAudienceList(response), appAPI.showMessage);
        setUpdateAudienceList(false)
    }

    const doUpdateUserTaskList = () => {
        handleResponse(fetchUserTasksAPI(contextAPI.username, contextAPI.password), (response) => setUserTaskList(response), appAPI.showMessage);
        setUpdateUserTaskList(false)
    }

    const doUpdateBranchList = () => {
        handleResponse(fetchBranchesAPI(contextAPI.username, contextAPI.password), (response) => setBranchList(response), appAPI.showMessage);
        setUpdateBranchList(false)
    }

    const doUpdateSourceList = () => {
        handleResponse(fetchSourcesAPI(contextAPI.username, contextAPI.password), (response) => {
            console.log(response);
            setSourceList(response)
        }, appAPI.showMessage);
        setUpdateSourceList(false)
    }

    const doUpdateDescriptorList = () => {
        if (source != -1) {
            handleResponse(fetchInterfaceAPI(source, contextAPI.username, contextAPI.password), (response) => setDescriptorList(response), appAPI.showMessage);
            setUpdateDescriptorList(false)
        }
    }

    React.useEffect(() => {
        if (updateActiveTaskList) {
            doUpdateActiveTaskList();
            setTimeout(() => setUpdateActiveTaskList(true), 1000);
        }
        if (updateAudienceList) {
            doUpdateAudienceList();
            setTimeout(() => setUpdateAudienceList(true), 1000);

        }
        if (updateUserTaskList) {
            doUpdateUserTaskList();
            setTimeout(() => setUpdateUserTaskList(true), 1000);
        }
        if (updateBranchList) {
            doUpdateBranchList();
        }
        if (updateSourceList) {
            doUpdateSourceList();
        }
        if (updateDescriptorList) {
            doUpdateDescriptorList();
        }
        if (next) {
            if (direction == 1 && businessIndex < audience.businessIdList.length - 2) {
                openNextBusiness()
            }
            else if (businessIndex > 0 && businessIndex - 1 != audience.businessIdList.length - 1) {
                openPrevBusiness();
            }
            setNext(false)
        }
    })

    const desktopLayout = true;

    return (
        <Box>
            <Box style={{ height: "50px" }} />
            <Box>
                <Grid spacing={5} container>
                    {
                        !desktopLayout ?
                            <Grid item xs={12}>
                                <ListContainer
                                    content={
                                        <Box>
                                            <ListFragmentActions
                                                contextAPI={contextAPI}
                                                audience={audience}
                                                business={business}
                                                setShowImportAudienceDialog={setShowImportAudienceDialog}
                                                exportAudience={exportAudience}
                                                setShowCreateAudienceDialog={setShowCreateAudienceDialog}
                                                setShowCreateTaskDialog={setShowCreateTaskDialog}
                                                setShowAddLead={setShowAddLead}
                                            />
                                            {
                                                audience != null && businessIndex != null ?
                                                    <ListFragmentNavigation
                                                        audience={audience}
                                                        curBusinessIndex={businessIndex}
                                                        skip={skip}
                                                        setSkip={setSkip}
                                                        setDirection={setDirection}
                                                        openNextBusiness={openNextBusiness}
                                                        openPrevBusiness={openPrevBusiness}
                                                    />
                                                    :
                                                    null
                                            }
                                        </Box>
                                    }
                                />
                            </Grid>
                            :
                            null
                    }
                    {
                        audience == null ?
                            null
                            :
                            <Grid xs={12} md={desktopLayout ? 8 : 12} item>
                                {
                                    business != null ?
                                        <Business
                                            appAPI={appAPI}
                                            contextAPI={contextAPI}
                                            updateBusiness={updateBusiness}
                                            showAddLead={showAddLead}
                                            setShowAddLead={setShowAddLead}
                                            business={business}
                                            createNote={createNote}
                                            setTaskStatus={setTaskStatus}
                                            openWebsite={openWebsite}

                                        />
                                        :
                                        null
                                }
                            </Grid>
                    }
                    {
                        desktopLayout ?
                            <Grid item xs={4}>
                                <ListContainer
                                    content={
                                        <Box>
                                            <ListFragmentActions
                                                contextAPI={contextAPI}
                                                audience={audience}
                                                business={business}
                                                setShowImportAudienceDialog={setShowImportAudienceDialog}
                                                exportAudience={exportAudience}
                                                setShowCreateAudienceDialog={setShowCreateAudienceDialog}
                                                setShowCreateTaskDialog={setShowCreateTaskDialog}
                                                setShowAddLead={setShowAddLead}
                                            />
                                            {
                                                audience != null && businessIndex != null ?
                                                    <ListFragmentNavigation
                                                        audience={audience}
                                                        curBusinessIndex={businessIndex}
                                                        skip={skip}
                                                        setSkip={setSkip}
                                                        setDirection={setDirection}
                                                        openNextBusiness={openNextBusiness}
                                                        openPrevBusiness={openPrevBusiness}
                                                    />
                                                    :
                                                    null
                                            }
                                            {
                                                audicenceList != null && audicenceList.length > 0 ?
                                                    <ListFragmentAudiences
                                                        openAudience={openAudience}
                                                        audicenceList={audicenceList}
                                                    />
                                                    :
                                                    null
                                            }
                                            {
                                                userTaskList != null && userTaskList.length > 0 ?
                                                    <ListFragmentTasks
                                                        business={business}
                                                        userTaskList={userTaskList}
                                                        openAudienceBusinessById={openAudienceBusinessById}
                                                    />
                                                    :
                                                    null
                                            }
                                            {
                                                activeTaskList != null && activeTaskList.length > 0 ?
                                                    <ListFragmentProcesses
                                                        activeTaskList={activeTaskList}
                                                    />
                                                    :
                                                    null
                                            }
                                        </Box>
                                    }
                                />
                            </Grid>
                            :
                            null
                    }
                    {
                        !desktopLayout ?
                            <Grid xs={12} item>
                                <ListContainer
                                    content={
                                        <Box>
                                            {
                                                audicenceList != null && audicenceList.length > 0 ?
                                                    <ListFragmentAudiences
                                                        openAudience={openAudience}
                                                        audicenceList={audicenceList}
                                                    />
                                                    :
                                                    null
                                            }
                                        </Box>
                                    }
                                />
                            </Grid>
                            :
                            null
                    }

                </Grid>
                {business != null ?
                    <DialogAddTask
                        open={showCreateTaskDialog}
                        close={() => setShowCreateTaskDialog(false)}
                        business={business}
                        createTask={createTask}
                    />
                    :
                    null
                }
                <DialogImportAudience
                    importAudience={importAudience}
                    showImportAudienceDialog={showImportAudienceDialog}
                    setShowImportAudienceDialog={setShowImportAudienceDialog}
                />
                <AddAudience
                    appAPI={appAPI}
                    contextAPI={contextAPI}

                    sourceList={sourceList}

                    source={source}

                    paramValuesDict={paramValuesDict}

                    descriptorList={descriptorList}
                    validateInput={validateInput}
                    updateSource={updateSource}
                    setParamter={setParamter}
                    getParameter={getParameter}
                    setParamterPrefix={setParamterPrefix}
                    getParameterPrefix={getParameterPrefix}
                    clearParamValuePrefixDicts={clearParamValuePrefixDicts}
                    showCreateAudienceDialog={showCreateAudienceDialog}
                    setShowCreateAudienceDialog={setShowCreateAudienceDialog}
                />
                <DialogShowWebsite
                    showWebsite={showWebsite}
                    setShowWebsite={setShowWebsite}
                    websiteUrl={websiteUrl}
                />
            </Box>
        </Box>
    )
}



function ListContainer(props) {
    return (
        <Paper style={{ padding: "5px", backgroundColor: PRIMARY_COLOR }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav">
                {props.content}
            </List>
        </Paper>
    )
}

const LABEL_SKIP = "Überspringen"

function ListFragmentNavigation(props) {

    /*Data*/
    const skip = props.skip
    const audience = props.audience;
    const curBusinessIndex = props.curBusinessIndex

    /*Functions*/
    const setSkip = props.setSkip
    const setDirection = props.setDirection
    const openNextBusiness = props.openNextBusiness
    const openPrevBusiness = props.openPrevBusiness

    return (
        <Box>
            <ListSubheader component="div">
                {LABLE_NAV + " (" + (curBusinessIndex + 1) + "/" + audience.businessIdList.length + ")"}
            </ListSubheader>
            <ListItemButton onClick={() => { setDirection(1); openNextBusiness() }} disabled={curBusinessIndex == null || curBusinessIndex == audience.businessIdList.length - 1}>
                <ListItemIcon>
                    <ArrowForwardIcon style={{ color: PRIMARY_COLOR }} />
                </ListItemIcon>
                <ListItemText primary={LABEL_NEXT} />
            </ListItemButton>
            <ListItemButton onClick={() => { setDirection(-1); openPrevBusiness() }} disabled={curBusinessIndex == null || curBusinessIndex == 0}>
                <ListItemIcon>
                    <ArrowBackIcon style={{ color: PRIMARY_COLOR }} />
                </ListItemIcon>
                <ListItemText primary={LABEL_PREV} />
            </ListItemButton>
            <ListItem>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={skip} onChange={(e) => setSkip(e.target.checked)} />} label={LABEL_SKIP} />
                </FormGroup>
            </ListItem>
        </Box>
    )
}


function ListFragmentActions(props) {

    /*Data*/
    const audience = props.audience;
    const business = props.business;
    /*Functions*/
    const setShowImportAudienceDialog = props.setShowImportAudienceDialog
    const exportAudience = props.exportAudience;
    const setShowCreateAudienceDialog = props.setShowCreateAudienceDialog;
    const setShowCreateTaskDialog = props.setShowCreateTaskDialog;
    const setShowAddLead = props.setShowAddLead;

    const contextAPI = props.contextAPI

    return (
        <Box>
            <ListSubheader component="div" id="nested-list-subheader">
                {LABEL_ACTIONS}
            </ListSubheader>
            <ListItemButton onClick={() => setShowCreateAudienceDialog(true)}>
                <ListItemIcon>
                    <AddCircleIcon style={{ color: PRIMARY_COLOR }} />
                </ListItemIcon>
                <ListItemText primary={LABEL_ADD} />
            </ListItemButton>
            {
                audience != null && business != null && contextAPI.admin ?
                    <ListItemButton onClick={exportAudience}>
                        <ListItemIcon>
                            <DownloadIcon style={{ color: PRIMARY_COLOR }} />
                        </ListItemIcon>
                        <ListItemText primary={LABEL_EXPORT} />
                    </ListItemButton>
                    :
                    null
            }
            {
                contextAPI.admin ?
                    <ListItemButton onClick={() => setShowImportAudienceDialog(true)}>
                        <ListItemIcon>
                            <UploadIcon style={{ color: PRIMARY_COLOR }} />
                        </ListItemIcon>
                        <ListItemText primary={LABEL_IMPORT_AUDIENCE} />
                    </ListItemButton>
                    :
                    null

            }
            {
                audience != null && business != null ?
                    <ListItemButton onClick={() => setShowCreateTaskDialog(true)}>
                        <ListItemIcon>
                            <NotificationsActiveIcon style={{ color: PRIMARY_COLOR }} />
                        </ListItemIcon>
                        <ListItemText primary={LABEL_ADD_TASK} />
                    </ListItemButton>
                    :
                    null
            }
            {
                audience != null && business != null ?
                    <ListItemButton onClick={() => setShowAddLead(true)}>
                        <ListItemIcon>
                            <CrisisAlertIcon style={{ color: PRIMARY_COLOR }} />
                        </ListItemIcon>
                        <ListItemText primary={LABEL_NEW_LEAD} />
                    </ListItemButton>
                    :
                    null
            }
        </Box>
    )
}

function ListFragmentAudiences(props) {

    const openAudience = props.openAudience;

    const audicenceList = props.audicenceList;

    return (
        <Box>
            <ListSubheader component="div">
                {LABEL_AUDIENCE_LIST}
            </ListSubheader>
            <Box style={{ maxHeight: "200px", overflow: "scroll" }}>
                {

                    audicenceList.map((audience) => {
                        return (
                            <ListItemButton onClick={() => openAudience(audience)} key={audience.audienceId}>
                                <ListItemIcon>
                                    <FilterAltIcon style={{ color: PRIMARY_COLOR }} />
                                </ListItemIcon>
                                <ListItemText primary={audience.audienceName} />
                            </ListItemButton>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

function ListFragmentProcesses(props) {

    const activeTaskList = props.activeTaskList.filter(task => task.status != "COMPLETED");

    return (
        <Box>
            {
                activeTaskList.length > 0 ?
                    <ListSubheader component="div" id="nested-list-subheader">
                        {LABEL_SELECTIONS}
                    </ListSubheader>
                    :
                    null
            }

            {
                activeTaskList.map(task => (
                    <ListItemButton disabled={true}>
                        <ListItemIcon>
                            {
                                task.status == "SCHEDULED" ?
                                    <HourglassTopIcon style={{ color: PRIMARY_COLOR }} />
                                    :
                                    null
                            }
                            {
                                task.status == "RUNNING" ?
                                    <LoopIcon style={{ color: PRIMARY_COLOR }} />
                                    :
                                    null
                            }
                            {
                                task.status == "COMPLETED" ?
                                    <DoneIcon style={{ color: PRIMARY_COLOR }} />
                                    :
                                    null
                            }
                            {
                                task.status == "FAILED" ?
                                    <CancelIcon style={{ color: PRIMARY_COLOR }} />
                                    :
                                    null
                            }
                            {
                                task.status == "COMPLETED_EMPTY" ?
                                    <CropSquareIcon style={{ color: PRIMARY_COLOR }} />
                                    :
                                    null
                            }
                        </ListItemIcon>
                        <ListItemText primary={task.name} />
                    </ListItemButton>
                ))
            }
        </Box>
    )

}

function ListFragmentTasks(props) {

    const business = props.business;
    const userTaskList = props.userTaskList;

    const openAudienceBusinessById = props.openAudienceBusinessById;

    let key = 0;

    const renderedList = [];

    return (
        <Box>
            <ListSubheader component="div">
                {LABEL_TASKS}
            </ListSubheader>
            <Box style={{ maxHeight: "200px", overflow: "scroll" }}>
                {

                    userTaskList.map((task) => {
                        if (!renderedList.includes(task.businessId)) {
                            renderedList.push(task.businessId)
                            return (
                                <ListItemButton onClick={() => {
                                    openAudienceBusinessById(task.audienceId, task.businessId);
                                }} key={key++} disabled={business != null && business.businessId == task.businessId}>
                                    <ListItemIcon>
                                        <NotificationsActiveIcon style={{ color: PRIMARY_COLOR }} />
                                    </ListItemIcon>
                                    <ListItemText primary={trim(task.relatedBusinessName, 30)} />
                                </ListItemButton>
                            )
                        }
                        return null;
                    })
                }
            </Box>
        </Box>
    )
}

const createUrlList = (business) => {
    const urllist = []
    if(business.websiteUrl != null) {
        urllist.push(business.websiteUrl)
    }
    if(business.facebookUrl != null) {
        urllist.push(business.facebookUrl)
    }
    if(business.instagramUrl != null) {
        urllist.push(business.instagramUrl)
    }
    if(business.youtubeUrl != null) {
        urllist.push(business.youtubeUrl)
    }
    if(business.linkedInUrl != null) {
        urllist.push(business.linkedInUrl)
    }
    if(business.xingUrl != null) {
        urllist.push(business.xingUrl)
    }
    return urllist;
}

const createLabelForUrl = (url) => {
    let label = null;
    if(url.includes("facebook")) {
        label = "Facebook";
    }
    else if(url.includes("instagram")) {
        label = "Instagram";
    }
    else if(url.includes("youtube")) {
        label = "Youtube";
    }
    else if(url.includes("linkedin")) {
        label = "LinkedIn";
    }
    else if(url.includes("xing")) {
        label = "Xing";
    }
    else {
        label = "Web"
    } 
    return label;
}



const getLabelForUrl = (url) => {
    let icon = null;
    if(url.includes("facebook")) {
        icon = <FacebookIcon style={{ color: PRIMARY_COLOR }} />;
    }
    else if(url.includes("instagram")) {
        icon = <InstagramIcon style={{ color: PRIMARY_COLOR }} />;
    }
    else if(url.includes("youtube")) {
        icon = <YouTubeIcon style={{ color: PRIMARY_COLOR }}/>;
    }
    else if(url.includes("linkedin")) {
        icon = <LinkedInIcon style={{ color: PRIMARY_COLOR }}/>;
    }
    else if(url.includes("xing")) {
        icon = <WorkOutlineIcon style={{ color: PRIMARY_COLOR }} />;
    }
    else {
        icon = <LanguageIcon style={{ color: PRIMARY_COLOR }}/>;
    } 
    return icon;
}

function Business(props) {

    const appAPI = props.appAPI
    const contextAPI = props.contextAPI
    const setTaskStatus = props.setTaskStatus
    const showAddLead = props.showAddLead
    const setShowAddLead = props.setShowAddLead
    const updateBusiness = props.updateBusiness;
    const openWebsite = props.openWebsite;

    const [showDialog, setShowDialog] = React.useState(false);
    const [phoneNo, setPhoneNo] = React.useState(false);


    const fetch = (obj, keyList) => {
        let index = 0;
        while (obj != null && index < keyList.length) {
            obj = obj[keyList[index]];
            index++
        }
        return obj;
    }

    const fetchFirst = (list) => list != null && list.length > 0 ? list[0] : null


    const style = {
        padding: "25px",
        border: "5px solid rgb(12, 5, 109)"
    }

    const business = props.business;

    const createNote = props.createNote

    return (
        <Box>
            <Paper style={style}>
                <Grid spacing={5} container>
                    <Grid xs={12} item>
                        <Typography variant="p" style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                            {business.businessId}: {business.businessName}
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Address
                            street={business.street}
                            houseNo={business.houseNo}
                            zipCode={business.zipCode}
                            town={business.town}
                        />
                    </Grid>
                    {
                        business.shareCapital == null ?
                            null
                            :
                            <Grid xs={12} md={6} item>
                                <Typography><b>Stammkapital: </b></Typography> {business.shareCapital / 100} EUR <br />
                            </Grid>
                    }
                    {

                    }
                    <Grid xs={12} md={6} item>
                        <IconListContext
                            heading={LABEL_PHONE}
                            icon={<LocalPhoneIcon style={{ color: PRIMARY_COLOR }} />}
                            itemList={business.phoneNoList.map(phoneNo => phoneNo.phoneNoStr)}
                            render={(text) => trim(text, 15)}
                            onClick={(item) => { setPhoneNo(item); setShowDialog(true); }}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <IconListContext
                            heading={LABEL_EMAIL}
                            icon={<EmailIcon style={{ color: PRIMARY_COLOR }} />}
                            itemList={business.emailAddressList.map(phoneNo => phoneNo.emailAddressStr)}
                            render={(text) => trim(text, 17)}
                        />
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <IconListContext
                            heading={LABEL_WEB}
                            icon={<LanguageIcon style={{ color: PRIMARY_COLOR }} />}
                            itemList={createUrlList(business)}
                            render={createLabelForUrl}
                            getLabelForUrl={getLabelForUrl}
                            onClick={(url) => window.open(url, '_blank').focus()}

                        //onClick={(url) => openWebsite(url)}
                        />
                    </Grid> 
                    {
                        business.shownTimestamp != null && business.reachedTimestamp == null ?
                            <Grid xs={12} item>
                                <Paper style={{ padding: "15px", backgroundColor: "orange", color: "white" }}>
                                    <WarningIcon style={{ float: "left", marginRight: "10px" }} />
                                    <Typography>Eine Rufnummer des Unternehmens wurde bereits am {new Date(business.shownTimestamp).toLocaleDateString()} abgerufen.</Typography>
                                </Paper>
                            </Grid>
                            :
                            null
                    }
                    {//|| business.reachedTimestamp != null
                        business.reachedTimestamp != null ?
                            <Grid xs={12} item>
                                <Paper style={{ padding: "15px", backgroundColor: "red", color: "white" }}>
                                    <WarningIcon style={{ float: "left", marginRight: "10px" }} />
                                    <Typography>Eine Rufnummer des Unternehmens wurde bereits am {new Date(business.reachedTimestamp).toLocaleDateString()} angerufen.</Typography>
                                </Paper>
                            </Grid>
                            :
                            null
                    }
                    <Grid xs={12} item>
                        <Notes
                            business={business}
                            createNote={createNote}
                        />
                    </Grid>
                    {
                        business != null && business.businessTaskViewList.length > 0 ?
                            <Grid xs={12} item>

                                <Tasks
                                    business={business}
                                    createNote={createNote}
                                    setTaskStatus={setTaskStatus}
                                />
                            </Grid>

                            :
                            null
                    }
                    <Grid xs={12} item>
                        {
                            business != null && business.personList.length > 0 ?
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}

                                        style={
                                            {
                                                backgroundColor: PRIMARY_COLOR,
                                                color: "white"
                                            }
                                        }
                                    >
                                        <PersonIcon />
                                        <Typography variant="div" style={{ fontFamily: "Arial, Helvetica", marginLeft: "10px", marginTop: "3px" }}>
                                            Personen
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ padding: "0px" }}>
                                        <Divider />
                                        <Table>
                                            <TableHead>
                                                <TableRow style={{ backgroundColor: PRIMARY_COLOR }}>
                                                    <TableCell style={{ color: "white", width: "25%" }}>Vorname</TableCell>
                                                    <TableCell style={{ color: "white", width: "25%" }}>Nachname</TableCell>
                                                    <TableCell style={{ color: "white", width: "25%" }}>Alter</TableCell>
                                                    <TableCell style={{ color: "white", width: "25%" }}>Rolle</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody style={{ margin: "0px", padding: "0px" }}>
                                                {
                                                    business.personList.map(person =>
                                                        <TableRow>
                                                            <TableCell>
                                                                {person.firstName}
                                                            </TableCell>
                                                            <TableCell>
                                                                {person.lastName}
                                                            </TableCell>
                                                            <TableCell>
                                                                {person.age == null ? "Keine Angabe" : person.age} {person.yearOfBirth == null ? null : "(" + person.yearOfBirth + ")"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {roleDict[person.role]}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion>


                                :
                                null
                        }
                    </Grid>

                </Grid>
            </Paper>
            <DialogShowPhoneNo
                appAPI={appAPI}
                contextAPI={contextAPI}
                showDialog={showDialog}
                closeDialog={() => setShowDialog(false)}
                phoneNo={phoneNo}
                updateBusiness={updateBusiness}
            />
            {
                business != null ?
                    <AddLead
                        appAPI={appAPI}
                        contextAPI={contextAPI}
                        reload={() => { }}
                        open={showAddLead}
                        setOpen={setShowAddLead}
                        businessId={business.businessId}
                        firm={business.businessName}
                        phoneNo={fetch(fetchFirst(business.phoneNoList), ["phoneNoStr"])}
                        emailAddress={fetch(fetchFirst(business.emailAddressList), ["emailAddressStr"])}
                        street={business.street}
                        houseNo={business.houseNo}
                        zipCode={business.zipCode}
                        town={business.town}
                    />
                    :
                    null
            }
        </Box>
    )
}

function Notes(props) {

    /*Date*/
    const business = props.business;

    /*Functions*/
    const createNote = props.createNote;

    const [note, setNote] = React.useState("");

    return (
        <Box>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    style={
                        {
                            backgroundColor: PRIMARY_COLOR,
                            color: "white"
                        }
                    }
                >
                    <NoteIcon />
                    <Typography variant="div" style={{ fontFamily: "Arial, Helvetica", marginLeft: "10px", marginTop: "3px" }}>
                        {LABEL_NOTES}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "25px" }}>
                    <Grid container spacing={5}>
                        {
                            business == null ?
                                null
                                :
                                business.businessNoteList.map((note) => {
                                    return (
                                        <Grid item xs={12} key={note.nodeId}>
                                            <TextField
                                                style={{ marginTop: "50px" }}
                                                value={note.noteStr}
                                                label={(note.firstName != null && note.lastName != null ? note.firstName + " " + note.lastName + ": " : "") + new Date(note.timestamp).toLocaleDateString()}
                                                variant="standard"
                                                multiline
                                                fullWidth
                                                error={false}
                                                disabled={true}
                                            />
                                        </Grid>
                                    )
                                })
                        }
                        <Grid item xs={12}>
                            <TextField
                                style={{ marginTop: "50px" }}
                                value={note}
                                variant="standard"
                                multiline
                                rows={3}
                                fullWidth
                                onChange={(e) => { setNote(e.target.value) }}
                                error={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" style={{ marginTop: "25px", color: "white", backgroundColor: SECONDARY_COLOR }} disabled={note == ""} onClick={() => { createNote(note); setNote("") }}>{LABEL_CREATE}</Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

function Tasks(props) {

    /*Data*/
    const business = props.business;

    /*Functions*/
    const setTaskStatus = props.setTaskStatus

    const [showDescription, setShowDescription] = React.useState(false)
    const [description, setDescription] = React.useState("")

    return (
        <Box>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                    style={
                        {
                            backgroundColor: PRIMARY_COLOR,
                            color: "white"
                        }
                    }
                >
                    <NotificationsActiveIcon />
                    <Typography variant="div" style={{ fontFamily: "Arial, Helvetica", marginLeft: "10px", marginTop: "3px" }}>
                        {LABEL_TASKS}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                    <TableContainer component={Box}>
                        <Divider />
                        <Table>
                            <TableHead>
                                <TableRow style={{ backgroundColor: PRIMARY_COLOR }}>
                                    <TableCell style={{ color: "white", width: "25%" }}>{LABEL_DESCRIPTION}</TableCell>
                                    <TableCell style={{ color: "white", width: "25%" }}>{LABEL_DUE_BY}</TableCell>
                                    <TableCell style={{ color: "white", width: "25%" }}>{LABEL_IN_CHARAGE}</TableCell>
                                    <TableCell style={{ color: "white", width: "25%" }}>{LABEL_STATUS}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    business.businessTaskViewList.map((task) => {
                                        return (
                                            <TableRow
                                                key={task.businessTaskId}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>
                                                    <IconButton onClick={() => { setDescription(task.description); setShowDescription(true); }} style={{ marginRight: "5px" }}>
                                                        <InfoIcon style={{ color: PRIMARY_COLOR }} />
                                                    </IconButton>
                                                    {trim(task.description, 10)}
                                                </TableCell>
                                                <TableCell>{task.dueBy == null ? null : new Date(task.dueBy).toLocaleDateString()}</TableCell>
                                                <TableCell>{task.lastName}, {task.firstName}</TableCell>
                                                <TableCell>
                                                    <Select value={task.status} onChange={(e) => setTaskStatus(task.businessTaskId, e.target.value)} variant="standard" fullWidth>
                                                        <MenuItem value={"OPEN"}>
                                                            {LABEL_STATUS_OPEN}
                                                        </MenuItem>
                                                        <MenuItem value={"DONE"}>
                                                            {LABEL_STATUS_DONE}
                                                        </MenuItem>
                                                    </Select>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            <Dialog
                open={showDescription}
                onClose={() => setShowDescription(false)}
                fullWidth
            >
                <DialogTitle>
                    {LABEL_DESCRIPTION}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        value={description}
                        fullWidth
                        multiline
                        disabled={true}
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDescription(false)}>{LABEL_STATUS_CLOSE}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

const generatePrefixList = (strList) => {
    const prefixList = [];
    let curMaxIndex = 1;
    while (curMaxIndex < 3) {

        for (let index = 0; index < strList.length; index++) {

            const curStr = strList[index];

            if (curMaxIndex < curStr.length) {
                const prefix = curStr.substring(0, curMaxIndex);
                if (!prefixList.includes(prefix)) {
                    prefixList.push(prefix);
                }
            }
        }
        curMaxIndex++;
    }
    return prefixList;
}

function DialogAddTask(props) {

    /*Styles*
    
    /
    /*Data*/
    const open = props.open;
    const business = props.business;

    const curDate = new Date();

    const [day, setDay] = React.useState(curDate.getDay() + 1)
    const [month, setMonth] = React.useState(curDate.getMonth() + 1)
    const [hour, setHour] = React.useState((curDate.getHours() + 1) % 24)
    const [min, setMin] = React.useState(0)
    const [year, setYear] = React.useState(curDate.getFullYear())
    const [description, setDescription] = React.useState("")

    const [dayList, setDayList] = React.useState(Array.from({ length: dayCount(curDate.getMonth(), curDate.getFullYear()) }, (_, i) => 0 + i).map(i => i + 1))

    const mothList = Array.from({ length: 12 }, (_, i) => 1 + i).map((x) => x)
    const yearList = Array.from({ length: 10 }, (_, i) => curDate.getFullYear() + i)
    const hourList = Array.from({ length: 24 }, (_, i) => 0 + i)
    const minList = Array.from({ length: 6 }, (_, i) => 0 + i)

    const updateMonth = (month) => {
        const maxDayCount = dayCount(month)
        if (day > maxDayCount) {
            setDay(maxDayCount)

        }
        setDayList(Array.from({ length: dayCount(month, year) }, (_, i) => 0 + i).map(i => i + 1), year)
        setMonth(month)
    }


    /*Functions*/
    const close = props.close
    const createTask = props.createTask

    const create = () => createTask(business.businessId, day, month, hour, min, description)

    const clear = () => {
        const curDate = new Date();
        setDay(curDate.getDay() + 1)
        setMonth(curDate.getMonth() + 1)
        setHour((curDate.getHours() + 1) % 24)
        setMin(0)
        setYear(curDate.getFullYear())
        setDescription("")
    }

    return (
        <Dialog
            open={open}
            onClose={() => { clear(); close() }}
            fullWidth
        >
            <DialogTitle>
                {LABEL_NEW_TASK}
            </DialogTitle>
            <DialogContent>
                <Grid spacing={6} container>
                    <Grid xs={12} md={6} item>
                        <Typography component={"p"}>{LABEL_DAY}:</Typography>
                        <Box>
                            <Select
                                style={{ width: "17%", marginRight: "5px" }}
                                variant="standard"
                                value={day}
                                onChange={(e) => setDay(e.target.value)}
                            >
                                {
                                    dayList.map(index => (
                                        <MenuItem key={index} value={index}>{index}</MenuItem>
                                    ))
                                }
                            </Select>.
                            <Select
                                style={{ width: "17%", marginLeft: "5px" }}
                                variant="standard"
                                value={month}
                                onChange={(e) => updateMonth(e.target.value)}
                            >
                                {
                                    mothList.map(index => (
                                        <MenuItem key={index} value={index}>{index}</MenuItem>
                                    ))
                                }
                            </Select>.
                            <Select
                                style={{ width: "27%", marginLeft: "5px" }}
                                variant="standard"
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                            >
                                {
                                    yearList.map(index => (
                                        <MenuItem key={index} value={index}>{index}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={6} item>
                        <Typography component={"p"}>{LABEL_TIME}:</Typography>
                        <Box>
                            <Select
                                style={{ width: "45%", marginRight: "5px" }}
                                variant="standard"
                                value={hour}
                                onChange={(e) => setHour(e.target.value)}
                            >
                                {
                                    hourList.map(index => (
                                        <MenuItem key={index} value={index}>{index < 10 ? "0" + index : index}</MenuItem>
                                    ))
                                }
                            </Select>:
                            <Select
                                style={{ width: "45%", marginLeft: "5px" }}
                                variant="standard"
                                value={min}
                                onChange={(e) => setMin(e.target.value)}
                            >
                                {
                                    minList.map(index => (
                                        <MenuItem key={index} value={10 * index}>{index == 0 ? "00" : 10 * index}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            label={LABEL_DESCRIPTION}
                            fullWidth
                            multiline
                            rows={5}
                            variant="standard"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { create(); clear(); close(); }}>{LABEL_SAVE}</Button>
                <Button onClick={() => { clear(); close(); }}>{LABEL_CANCEL}</Button>
            </DialogActions>
        </Dialog>
    )
}



const LABEL_NEW_AUDIENCE = "Audience anlegen";
const LABEL_AUDIENCE_NAME = "Name";
const LABEL_BRANCH = "Branche";
const LABEL_RADIUS = "Radius";
const LABEL_POSTCODE_AREAD = "Postleitzahlbereich";
const LABEL_ZIP_CODE = "Postleitzahl";
const LABEL_ZIP_CODE_START = "Postleitzahl (Start)";
const LABEL_ZIP_CODE_END = "Postleitzahl (Stop)";

const LABEL_MIN_AGE = "Mindestalter";
const LABEL_MAX_AGE = "Maximalalter";

const LABEL_PLEASE_CHOOSE = "Bitte wählen...";
const LABEL_SEARCH_MODE = "Suchmodus";

function AddAudience(props) {


    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI


    const [name, setName] = React.useState("")
    const [mode, setMode] = React.useState(0)
    const [radius, setRadius] = React.useState(5)
    const [radiusZipCode, setRadiusZipCode] = React.useState("")

    const source = props.source;
    const descriptorList = props.descriptorList;
    const updateSource = props.updateSource;
    const setParamter = props.setParamter;
    const getParameter = props.getParameter;
    const setParamterPrefix = props.setParamterPrefix;
    const getParameterPrefix = props.getParameterPrefix;


    const [zipCodeStart, setZipCodeStart] = React.useState("")
    const [zipCodeEnd, setZipCodeEnd] = React.useState("")

    const [minAge, setMinAge] = React.useState(18)
    const [maxAge, setMaxAge] = React.useState(100)

    const [isNameValid, setIsNameValid] = React.useState(false)
    const [isCentroidZipCodeValid, setCentroidZipCodeValid] = React.useState(false)
    const [isMinZipCodeValid, setMinZipCodeValid] = React.useState(false)
    const [isMaxZipCodeValid, setMaxZipCodeValid] = React.useState(false)


    const ageList = Array.from({ length: 100 - 18 + 1 }, (_, i) => 18 + i) 

    const close = () => {
        setName("")
        setMode(0)
        setRadiusZipCode("")
        setZipCodeEnd("")
        setZipCodeStart("")
        setIsNameValid(false)
        setCentroidZipCodeValid(false)
        setMinZipCodeValid(false)
        setMaxZipCodeValid(false)
        props.setShowCreateAudienceDialog(false)
        updateSource(null)
    }

    const createRangeAudience = () => {
        // handleResponse(createRangeAudienceAPI(contextAPI.username, contextAPI.password, name, branch, zipCodeStart, zipCodeEnd, minAge, maxAge), (response) => { }, appAPI.showMessage);
    }

    const createRadiusAudience = () => {
        handleResponse(createRadiusAudienceAPI(contextAPI.username, contextAPI.password, name, source, radiusZipCode, radius, props.paramValuesDict), (response) => { }, appAPI.showMessage);
    }

    // createRadiusAudienceAPI = (username, password, name, zipCode, radius, argumentMap)

    const createAudience = () => {
        if (mode == 0) {
            createRadiusAudience()
        } 
        else {
            createRangeAudience();
        }
        close()
    }

    const updatedName = (name) => {
        const regex = /^[A-Za-z0-9_.]+$/;
        setIsNameValid(regex.test(name))
        setName(name)
    }

    const updateRadiusZipCode = (zipCode) => {
        const regex = /^[0-9]{5}$/;
        setCentroidZipCodeValid(regex.test(zipCode))
        setRadiusZipCode(zipCode)
    }

    const updateMinZipCode = (zipCode) => {
        const regex = /^[0-9]{5}$/;
        setMinZipCodeValid(regex.test(zipCode))
        setZipCodeStart(zipCode)
    }

    const updateMaxZipCode = (zipCode) => {
        const regex = /^[0-9]{5}$/;
        setMaxZipCodeValid(regex.test(zipCode))
        setZipCodeEnd(zipCode)
    }


    return (
        <Dialog
            open={props.showCreateAudienceDialog}
            onClose={() => { }}
            fullWidth
        >
            <DialogTitle>
                {LABEL_NEW_AUDIENCE}
            </DialogTitle>
            <DialogContent style={{ padding: "25px" }}>
                <Grid spacing={3} container>
                    <Grid xs={12} item>
                        <TextField error={!isNameValid} label={LABEL_AUDIENCE_NAME} variant="outlined" value={name} onChange={(e) => updatedName(e.target.value)} fullWidth />
                    </Grid>
                    <Grid xs={12} item>
                        <Divider />
                    </Grid>
                    <Grid xs={12} item>
                        <FormControl fullWidth>
                            <InputLabel>{"Quelle"}</InputLabel>
                            <Select
                                value={source == null ? -1 : source}
                                onChange={(e) => updateSource(e.target.value)}
                                variant="outlined"
                            >
                                {
                                    props.sourceList
                                        .map(source => <MenuItem value={source}>{source}</MenuItem>)
                                }
                                <MenuItem value={-1} disabled>Bitte wählen...</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid xs={12} item>
                        <Divider />
                    </Grid> 
                    {
                        source != null ?
                            descriptorList.filter(descriptor => descriptor.name !== "zip_code").map((descriptor) =>
                                <AddAudienceInput
                                    descriptor={descriptor}
                                    getParameter={getParameter}
                                    setParamter={setParamter}
                                    getParameterPrefix={getParameterPrefix}
                                    setParamterPrefix={setParamterPrefix}

                                />
                            )
                            :
                            null
                    }
                    <Grid xs={12} item>
                        <FormControl fullWidth>
                            <InputLabel>{LABEL_SEARCH_MODE}</InputLabel>
                            <Select
                                value={mode}
                                onChange={(e) => setMode(e.target.value)}
                                variant="outlined"
                            >
                                <MenuItem value={0}>{LABEL_RADIUS}</MenuItem>
                                <MenuItem value={1}>{LABEL_POSTCODE_AREAD}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        mode == 0 ?
                            <Grid xs={12} item>
                                <TextField error={!isCentroidZipCodeValid} value={radiusZipCode} label={LABEL_ZIP_CODE} onChange={(e) => updateRadiusZipCode(e.target.value)} variant="outlined" fullWidth />
                            </Grid>
                            :
                            null
                    }
                    {
                        mode == 0 ?
                            <Grid xs={12} item>
                                <FormControl fullWidth>
                                    <InputLabel>{LABEL_RADIUS}</InputLabel>
                                    <Select
                                        value={radius}
                                        onChange={(e) => setRadius(e.target.value)}
                                        variant="outlined"
                                    >
                                        <MenuItem value={5}>5 km</MenuItem>
                                        <MenuItem value={10}>10 km</MenuItem>
                                        <MenuItem value={25}>25 km</MenuItem>
                                        <MenuItem value={50}>50 km</MenuItem>
                                        <MenuItem value={100}>100 km</MenuItem>
                                        <MenuItem value={200}>200 km</MenuItem>
                                        <MenuItem value={300}>300 km</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            :
                            null
                    }
                    {
                        mode == 1 ?
                            <Grid xs={6} item>
                                <TextField error={!isMinZipCodeValid} value={zipCodeStart} onChange={(e) => updateMinZipCode(e.target.value)} label={LABEL_ZIP_CODE_START} variant="outlined" fullWidth />
                            </Grid>
                            :
                            null
                    }
                    {
                        mode == 1 ?
                            <Grid xs={6} item>
                                <TextField error={!isMaxZipCodeValid} value={zipCodeEnd} onChange={(e) => updateMaxZipCode(e.target.value)} label={LABEL_ZIP_CODE_END} variant="outlined" fullWidth />
                            </Grid>
                            :
                            null
                    }

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>{LABEL_CANCEL}</Button>
                <Button disabled={(mode == 0 && (!isNameValid || !isCentroidZipCodeValid)) || (mode == 1 && (!isNameValid || !isMinZipCodeValid || !isMaxZipCodeValid)) || !props.validateInput()} onClick={() => createAudience()} autoFocus>{LABEL_CREATE}</Button>
            </DialogActions>
        </Dialog>
    )
}



function AddAudienceTextInput(props) {

    const descriptor = props.descriptor;
    const getParameter = props.getParameter;
    const setParamter = props.setParamter;


    const value = getParameter(descriptor.name);
    const isInvalid = !descriptor.optional && getParameter(descriptor.name) == null || descriptor.javascriptRegex != null && !new RegExp(descriptor.javascriptRegex).test(value)


    return ( 
        <Grid xs={12} key={descriptor.name} item> 
            <Grid spacing={3} container>
                <Grid xs={12} item>
                    <TextField error={isInvalid} label={descriptor.name} value={value == null ? "" : value} onChange={(e) => setParamter(descriptor.name, e.target.value)} variant="outlined" fullWidth
                        InputProps={
                            descriptor.optional ?
                                {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setParamter(descriptor.name, null)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                                :
                                null
                        }
                    />
                </Grid>
                <Grid xs={12} item>
                    <Divider />
                </Grid>
            </Grid>
        </Grid>
    )
}

function AddAudienceSelectInput(props) {

    const descriptor = props.descriptor;
    const getParameter = props.getParameter;
    const setParameter = props.setParamter;
    const getParameterPrefix = props.getParameterPrefix;
    const setParamterPrefix = props.setParamterPrefix;

    const valueArr = getParameter(descriptor.name);
    const prefix = getParameterPrefix(descriptor.name);

    const value = valueArr != null && valueArr.length > 0 ? valueArr[0] : null

    return (
        <Grid xs={12} key={descriptor.name} item>
            <Grid spacing={3} container>
                <Grid xs={12} item>
                    <FormControl fullWidth>
                        <InputLabel>{descriptor.name}</InputLabel>
                        <Select
                            value={prefix == null ? -1 : prefix}
                            onChange={(e) => {setParamterPrefix(descriptor.name, e.target.value); setParameter(descriptor.name, null)}}
                            variant="outlined"
                        >   
                            {
                                generatePrefixList(descriptor.defaultValues)
                                    .map(prefix => <MenuItem key={prefix} value={prefix}>{prefix}</MenuItem>)
                            }
                            <MenuItem value={-1}>Bitte wählen...</MenuItem>
                        </Select>
                    </FormControl> 
                </Grid> 
                {
                    prefix != null ?
                        <Grid xs={12} item>
                            <FormControl fullWidth> 
                                <InputLabel>{descriptor.name}</InputLabel>
                                <Box display="flex" alignItems="center" >
                                    <Select
                                        value={value == null ? -1 : value}
                                        onChange={(e) => setParameter(descriptor.name, e.target.value)}
                                        variant="outlined"
                                        fullWidth={true} // Let Select take full width only when optional button is absent
                                    >
                                        {descriptor.defaultValues
                                            .filter(value =>
                                                prefix == null || value.startsWith(prefix)
                                            )
                                            .map(prefix => (
                                                <MenuItem key={prefix} value={prefix}>{prefix}</MenuItem>
                                            ))
                                        }
                                        <MenuItem value={-1} disabled>Bitte wählen...</MenuItem>
                                    </Select>
                                    {descriptor.optional && (
                                        <IconButton onClick={() => setParameter(descriptor.name, null)} style={{marginLeft: "20px"}}>
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            </FormControl> 
                        </Grid>
                        :
                        null
                }
                <Grid xs={12} item>
                    <Divider />
                </Grid>
            </Grid>
        </Grid>
    )
}


function AddAudienceInput(props) {

    const descriptor = props.descriptor;
    const getParameter = props.getParameter;
    const setParamter = props.setParamter;
    const getParameterPrefix = props.getParameterPrefix;
    const setParamterPrefix = props.setParamterPrefix;


    return (

        descriptor.defaultValues == null || descriptor.defaultValues.length == 0 ?
            <AddAudienceTextInput
                descriptor={descriptor}
                getParameter={getParameter}
                setParamter={setParamter}
            />
            :
            <AddAudienceSelectInput
                descriptor={descriptor}
                getParameter={getParameter}
                setParamter={setParamter}
                getParameterPrefix={getParameterPrefix}
                setParamterPrefix={setParamterPrefix}
            />
    )
}


function Address(props) {
    return (
        <Box>
            <Typography variant="p" style={{ fontWeight: "bold", fontSize: "1rem" }}>{LABEL_ADDRESS}</Typography><br />
            <Box style={{ marginTop: "10px" }}>
                <Typography variant="p">{props.street} {props.houseNo}</Typography><br />
                <Typography variant="p">{props.zipCode} {props.town}</Typography><br />
            </Box>
        </Box>
    )
}

function IconListContext(props) {

    const heading = props.heading;
    const icon = props.icon;
    const itemList = props.itemList;
    const render = props.render
    const onClick = props.onClick
    const getLabelForUrl= props.getLabelForUrl

    return (
        <Box>
            <Typography variant="p" style={{ fontWeight: "bold", fontSize: "1rem" }}>{heading}</Typography>
            <IconList
                icon={icon}
                getLabelForUrl={getLabelForUrl}
                itemList={itemList}
                render={render}
                onClick={onClick}
            />
        </Box>
    )
}

function IconList(props) {

    const icon = props.icon;
    const getLabelForUrl = props.getLabelForUrl;
    const itemList = props.itemList;
    const render = props.render
    const onClick = props.onClick

    const copy = (value) => { navigator.clipboard.writeText(value) }

    return (

        <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
        >
            {
                itemList != null && itemList.length > 0 ?

                    itemList.map((item) => {
                        return (
                            <ListItemButton onClick={() => { copy(item); onClick(item) }}>
                                <ListItemIcon>
                                    {getLabelForUrl != null ? getLabelForUrl(item) : icon}
                                </ListItemIcon>
                                <ListItemText primary={render(item)} />
                            </ListItemButton>

                        ) 
                    })
                    :
                    <ListItemButton disabled={true}>
                        <ListItemIcon>
                            {icon}
                        </ListItemIcon>
                        <ListItemText primary={"Keine Ergebnisse"} />
                    </ListItemButton>
            }
        </List>
    )
}



const LABEL_REACHED = "Erreicht"
const LABEL_NOT_REACHED = "Nicht erreicht"
const LABEL_IMPORT_AUDIENCE = "Audience importieren"
const LABEL_IMPORT = "Importieren"
const LABEL_ABORT = "Abbrechen"

function DialogImportAudience(props) {


    const importAudience = props.importAudience;
    const showImportAudienceDialog = props.showImportAudienceDialog
    const setShowImportAudienceDialog = props.setShowImportAudienceDialog

    const [username, setUsername] = React.useState("");
    const [json, setJson] = React.useState("");

    const clear = () => {
        setUsername("")
        setJson("")
    }

    return (
        <Dialog
            open={showImportAudienceDialog}
            onClose={() => setShowImportAudienceDialog(false)}
            fullWidth
        >
            <DialogTitle>
                {LABEL_IMPORT_AUDIENCE}
            </DialogTitle>
            <DialogContent>
                <Grid spacing={5} container>
                    <Grid xs={12} item>
                        <TextField
                            label={"Benutzername"}
                            rows={5}
                            fullWidth
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            label={"JSON"}
                            multiline
                            rows={5}
                            fullWidth
                            value={json}
                            onChange={(e) => setJson(e.target.value)}
                        />
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => { importAudience(json, username); clear(); setShowImportAudienceDialog(false) }}>{LABEL_IMPORT}</Button>
                <Button onClick={() => { clear(); setShowImportAudienceDialog(false) }}>{LABEL_ABORT}</Button>
            </DialogActions>
        </Dialog>
    )
}


function DialogShowPhoneNo(props) {


    const appAPI = props.appAPI;
    const contextAPI = props.contextAPI;

    const showDialog = props.showDialog

    const closeDialog = props.closeDialog;

    const [phoneNo, setPhoneNo] = React.useState(null);


    const shown = (phoneNo) => {
        handleResponse(setShownAPI(contextAPI.username, contextAPI.password, phoneNo), (response) => { props.updateBusiness() }, appAPI.showMessage)
    };


    const reached = () => {
        handleResponse(setReachedAPI(contextAPI.username, contextAPI.password, phoneNo), (response) => { props.updateBusiness() }, appAPI.showMessage)
    };

    React.useEffect(() => {
        if (phoneNo != props.phoneNo) {
            setPhoneNo(props.phoneNo);
            shown(props.phoneNo)
        }
    })

    return (
        <Dialog
            open={showDialog}
            onClose={closeDialog}
            fullWidth
        >
            <DialogTitle>
                {LABEL_PHONE_NUMBER}
            </DialogTitle>
            <DialogContent>
                <Typography variant="p" style={{ fontFamily: 'Arial, Helvectica', fontSize: "1rem" }}>{props.phoneNo}</Typography><br />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { reached(); closeDialog(); }}>{LABEL_REACHED}</Button>
                <Button onClick={closeDialog}>{LABEL_NOT_REACHED}</Button>
            </DialogActions>
        </Dialog>
    )
}

function DialogShowWebsite(props) {


    return (<Dialog
        fullScreen
        open={props.showWebsite}
        onClose={() => props.setShowWebsite(false)}
    >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => props.setShowWebsite(false)}
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
        <iframe style={{ height: "100%" }} src={props.websiteUrl} ></iframe>
    </Dialog>
    )
}

